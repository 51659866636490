import React from 'react';
import { Container, Grid, Box } from '@mui/material';
import { DNA } from 'react-loader-spinner';

export default function MainLoading() {
    return (
      <Container
        maxWidth={false}
        sx={{ textAlign: "center", mt: "1rem", fontFamily: "Iransans" }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <Box>
                <DNA
                  visible={true}
                  height="120"
                  width="120"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
}
