import { combineReducers } from "redux";
import { loadReducer } from "./loadReducer";
import { authenticationReducer } from "./authenticationReducer";
import { configReducer } from "./configReducer";

export default combineReducers({
  loadReducerState: loadReducer,
  authenticationReducerState: authenticationReducer,
  configReducerState: configReducer,
});
