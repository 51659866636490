import { TOKEN_INFO, USER_INFO } from "stateManagement/actions/ationTypes";

const authenticationDetailIntial = {
  token: "",
  user: "",
};

export const authenticationReducer = function (
  state = authenticationDetailIntial,
  action
) {
  switch (action.type) {
    case TOKEN_INFO:
      return {
        ...state,
        token: action.payload.token,
      };

    case USER_INFO:
      return {
        ...state,
        user: action.payload.user,
      };

    default:
      return state;
  }
};
