import { LOAD_INFO, LOADING_DATA_INFO } from "stateManagement/actions/ationTypes";

const loadDetailIntial = {
  load: 0,
  loadingData: false,
};

export const loadReducer = function (state = loadDetailIntial, action) {
  switch (action.type) {
    case LOAD_INFO:
      return {
        ...state,
        load: action.payload.load,
      };
    case LOADING_DATA_INFO:
      return {
        ...state,
        loadingData: action.payload.loadingData,
      };
    default:
      return state;
  }
};
