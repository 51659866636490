// === Authentication
export const TOKEN_INFO = 'TOKEN_INFO';
export const USER_INFO = "USER_INFO";

// === load
export const LOAD_INFO = "LOAD_INFO";
export const LOADING_DATA_INFO = "LOADING_DATA_INFO";

// === config
export const LANG_INFO = 'LANG_INFO';
export const DIR_INFO = 'DIR_INFO';


