import React, { Suspense } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./stateManagement/store";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Container } from "@mui/material";
import "static/css/styles.css";

import MainLoading from "./views/modules/loading/main_loading";

const Main = React.lazy(() => import("views/layout/main"));

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={<MainLoading />} persistor={persistor}>
          <Container maxWidth={false} sx={{ p: "0 !important" }}>
            <Router>
              <Suspense fallback={<MainLoading />}>
                <Routes>
                  <Route path="/*" element={<Main />} />
                </Routes>
              </Suspense>
            </Router>
          </Container>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
