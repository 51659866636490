import { LANG_INFO, DIR_INFO } from 'stateManagement/actions/ationTypes';

const configDetailIntial = {
    lang: 'en',
    dir: 'ltr'
};

export const configReducer = function (state = configDetailIntial, action) {
    switch (action.type) {
        case LANG_INFO:
            return {
                ...state,
                lang: action.payload.lang
            };
        case DIR_INFO:
            return {
                ...state,
                dir: action.payload.dir
            };
        default:
            return state;
    }
};
